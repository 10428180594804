import React, { useState, useEffect } from 'react';

import { RegDiv } from '../..';
import { GetStoreFunctions } from '../../Api';

function CNStoreFunctions({onCancel, onConfirm}){
    const [storeFunctions, setStoreFunctions] = useState();
    const [monthFee, setMonthFee] = useState(30);
    const [monthTotal, setMonthTotal] = useState(31.5);
    const [monthTax, setMonthTax] = useState(1.5);
    const [featureCount, setFeatureCount] = useState(0);
    const fetchStorefunctions = async () => {
        try {
            const usr_json = sessionStorage.getItem("userinfo");
            var userinfo = JSON.parse(usr_json);
           
            if(userinfo)
            {
                const data = await GetStoreFunctions(userinfo.StoreId);
                 
                setStoreFunctions(data);
                return data;
            }
        } catch (error) {
          console.error('数据获取错误:', error);
        }
    };

    const calculateMonthlyFees = (feature)=>{
        let count=0;
        
        if(feature !==null && feature !== undefined)
        {            
            if(feature.BilingualMenus) {
                count++;
            }
            if(feature.ClockService ){
                count++;
            }
            if(feature.DeliveryService ){
                count++;
            }
            if(feature.DualMonitor ){
                count++;
            }
            if(feature.EMenuSelfOrder){
                count++;
            }
            if(feature.InventoryControl){
                count++;
            }
            if(feature.KitchenScreen ){
                count++;
            }
            if(feature.MobileOrder){
                count++;
            }
            if(feature.MultiOrder){
                count++;
            }
            if(feature.PickupService){
                count++;
            }
            if(feature.RandomOrderNumber){
                count++;
            }
            if(feature.SmartWait){
                count++;
            }
            if(feature.TableService){
                count++;
            }
            if(feature.VIP){
                count++;
            }
        }

        
        let fee = 30; // base charge
        fee += count * 5; //every function $5/month
        setFeatureCount(count);
        return fee;
    }
  
    const SetDisplayData = async ()=>{
        let feature =await fetchStorefunctions();
        let fee = calculateMonthlyFees(feature);
        setMonthFee(fee);
        let tax = fee * 0.05;
        setMonthTax(tax);
        let total = fee * (1.05); //add gst
        setMonthTotal(total);
    }
    useEffect(() => {      
        SetDisplayData();
    }, []);

    return(
        <RegDiv flexdirection="row"> 
            
            <RegDiv >  
                <RegDiv>
                    <h2>您选择的功能</h2>       
                    
                    <ul >
                    { storeFunctions && storeFunctions.BilingualMenus && (
                    <li>双语菜单 </li>
                    )}
                    { storeFunctions && storeFunctions.ClockService && (
                    <li>打卡功能 </li>
                    )}
                    { storeFunctions && storeFunctions.DeliveryService && (
                    <li>送餐外卖</li>
                    )}
                    { storeFunctions && storeFunctions.DualMonitor && (
                    <li>客人屏显 </li>
                    )}
                    { storeFunctions && storeFunctions.EMenuSelfOrder && (
                    <li>扫码点餐 </li>
                    )}
                    { storeFunctions && storeFunctions.InventoryControl && (
                    <li>存货管理</li>
                    )}
                    { storeFunctions && storeFunctions.KitchenScreen && (
                    <li>厨房屏显 </li>
                    )}
                    { storeFunctions && storeFunctions.MobileOrder && (
                    <li>移动点餐</li>
                    )}
                    { storeFunctions && storeFunctions.MultiOrder && (
                    <li>一台多单 </li>
                    )}
                    { storeFunctions && storeFunctions.PickupService && (
                    <li>外卖自取 </li>
                    )}
                    { storeFunctions && storeFunctions.RandomOrderNumber && (
                    <li>随机单号</li>
                    )}
                    { storeFunctions && storeFunctions.SmartWait && (
                    <li>智能等位</li>
                    )}
                    { storeFunctions && storeFunctions.TableService && (
                    <li>桌台服务 </li>
                    )}
                    { storeFunctions && storeFunctions.VIP && (
                    <li>贵宾卡服务 </li>
                    )}              
                    </ul> 
                   
                    <p>功能总数:  {featureCount} <br/>
                    基本费用是 $30/月, 每项功能收费 $5/月.</p>
                    <p>总共月费: 30 + 5* {featureCount} = ${monthFee} <br/>
                    + 相应的税如GST/PST/HST等</p> 
                </RegDiv>
                
                <RegDiv flexdirection="row">
                    <RegDiv width ="20vw">
                        <button onClick={onConfirm}
                        style={{
                        width: '80px',
                        height: '40px',                  
                        }}
                        >   
                        确认</button> 
                    </RegDiv>
                    <RegDiv width="20vw">
                        <button onClick={onCancel}
                        style={{
                            width: '80px',
                            height: '40px',                  
                            }}
                        >
                            取消</button>  
                    </RegDiv>
                
                </RegDiv>
                
            </RegDiv>
            
        </RegDiv>
    )
}

export default CNStoreFunctions;